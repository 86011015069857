import { defineStore } from "pinia";
import { useStorage, useSessionStorage } from "@vueuse/core";

export const useCommonStore = defineStore("common", {
    state: () => ({
        isMenuShow: true,
    }),
    actions: {
        chgMenuShow() {
            console.log("chk");
            this.isMenuShow = !this.isMenuShow;
        },
    },
    persist: {
        enabled: true,
        strategies: [{ storage: sessionStorage, paths: ["common"] }],
    },
});

export const useSessonStore = defineStore("sesssion", {
    state: () => ({
        isLogin: useStorage("isLogin", false),
        token: useStorage("token", ""),
        memberId: "",
    }),
    actions: {
        getToken() {
            return this.token;
        },
        logout() {
            this.token = "";
            this.isLogin = false;
            return true;
        },
        setToken(val) {
            this.token = val;
            this.isLogin = true;
        },
    },

    //     persist: {
    //         enabled: true,
    //         strategies: [{ storage: "localStorage", paths: ["session"] }],
    //     },
});
export const useValidateStore = defineStore("validate", {
    state: () => ({
        errors: useSessionStorage("errors", []),
        rule: useSessionStorage("rule", []),
    }),
    actions: {
        clear() {
            this.errors = [];
            this.rule = [];
        },
        clearFldError(fld) {
            this.errors = this.errors.filter(e => e.fldName !== fld); 
        },
        addRule(fld, fldnm, nm, ...c) {
            this.rule.push({ fld: fld, fldName: fldnm, nm: nm, fnc: c });
        },
        pushError(v) {
            this.errors.push(v);
        },
        setError(fld, err) {
            this.errors = this.errors.filter(e => e.fldName !== fld); 

            this.pushError({fldName: fld, errmsg:err}) 
        },
        async checkAll() {
            this.errors = [];
            for (let r in this.rule) {
                for (let i in this.rule[r].fnc) {
                    const err = await this.rule[r].fnc[i](this.rule[r].fld, this.rule[r].nm);

                    if (err !== "") {
                        this.pushError({ fldName: this.rule[r].fldName, errmsg: err });
                        break;
                    }
                }
            }
            if (this.errors.length > 0) return true;
            return false;
        },
        errorMessage(v) {
            const msg = this.errors.find((e) => e.fldName === v);
            if (!msg && msg === undefined) return "";
            return msg.errmsg;
        },
        isError() {
            if (this.errors.length > 0) {
                return true;
            }
            return false;
        },
        isFldError(fld) {
            return this.errors.some(e => e.fldName === fld);
        }
    },
});
