import moment from "moment";
export class helpersClass {
    numberFormat = (price) => {
        const num = String(price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        if (num === "null") return "";
        return num;
    };
        /*****************************************
         * 日付のフォーマット 
         * @param {日付} ymd 
         * @returns 
         ****************************************/
        dateFormat =  (ymd) => {
            if (ymd === null || !moment(ymd).isValid) {
                return '';
            }

            const date = moment(ymd);
            return date.format('YYYY-MM-DD');
        }
        dateFromToCheck = (from, to) =>{

            if (to === null || !moment(to).isValid) {
                return true;
            }
            if (from === null || !moment(from).isValid) {
                return true;
            }
            const toDate = moment(to);
            const fromDate = moment(from);
            if (fromDate > toDate) {
                return false;
            }
            return true;
        }
        toBlob = (data64, mime_ctype) => {
            var bin = atob(data64.replace(/^.*,/, ''));
            var buffer = new Uint8Array(bin.length);
            for (var i = 0; i < bin.length; i++) {
            buffer[i] = bin.charCodeAt(i);
            }
            // Blobを作成
            try {
            var blob = new Blob([buffer.buffer], {
            type: mime_ctype,
            });
            } catch (e) {
            return false;
            }
            return blob;
        }
}
export const helpers = new helpersClass();

export class errorCheckClass {
    /*****************************************
     * 郵便番号チェック 
     * @param {郵便番号} str 
     * @returns 
     ****************************************/
    isPostCode =  (str) =>  {
        if ( str.match(/^\d{3}-?\d{4}$/) ) {
            return true;
        } 
        return false;
    }
    /*****************************************
     * メールアドレスチェック 
     * @param {文字列}} ymd 
     * @returns 
     ****************************************/
    isMailaddress =  (str) => {
        str = (str == null) ? "" : str;
        if (str.match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)) {
            return true;
        } else {
            return false;
        }
    }
    /*****************************************
     * 電話番号チェック 
     * @param {文字列}} ymd 
     * @returns 
     ****************************************/
    isTelNo = (str)  => {
        str = (str == null) ? "" : str;
        if (str.match(/^(0{1}\d{1,4}-{0,1}\d{1,4}-{0,1}\d{4})$/)) {
            return true;
        } else {
            return false;
        }
    }
    /*****************************************
     * 半角英数チェック 
     * @param {文字列}} ymd 
     * @returns 
     ****************************************/
    isHanEisu =  (str) =>{
        str = (str == null) ? "" : str;
        if (str.match(/^[A-Za-z0-9]*$/)) {
            return true;
        } else {
            return false;
        }
    }
}
export const errorCheck = new errorCheckClass();