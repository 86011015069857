<template>
    <v-app-bar color="blue-grey darken-2" dark app clipped-left v-if="sessonStore.isLogin">
        <v-app-bar-nav-icon @click="chgShowMenu"></v-app-bar-nav-icon>
        <v-toolbar-title>建設発生土マッチングシステム管理者画面</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn outlined @click="logout">ログアウト</v-btn>
    </v-app-bar>
</template>
<script setup>
import { } from "vue";
import { useCommonStore, useSessonStore } from "@/stores/common";
import { useRouter } from "../commons/useRouter"


const commonStore = useCommonStore();
const sessonStore = useSessonStore();
const router = useRouter();
/**
 * メニューの表示
 */
const chgShowMenu = () => {
    commonStore.chgMenuShow();
};
/**
 * ログアウト
 */
const logout = () => {
    sessonStore.logout();
    router.push("/login");
};
//const isLogin = ref(true);
</script>
