/**
 * vue-router composition api 風
 *
 */
import { getCurrentInstance } from "vue";

export function useRouter() {
    const instance = getCurrentInstance();
    if (!instance) {
        throw new Error("Shuld be use in setup");
    }
    return instance.proxy.$router;
}
