var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.sessonStore.isLogin
    ? _c(
        "v-app-bar",
        {
          attrs: {
            color: "blue-grey darken-2",
            dark: "",
            app: "",
            "clipped-left": "",
          },
        },
        [
          _c("v-app-bar-nav-icon", { on: { click: _setup.chgShowMenu } }),
          _c("v-toolbar-title", [
            _vm._v("建設発生土マッチングシステム管理者画面"),
          ]),
          _c("v-spacer"),
          _c(
            "v-btn",
            { attrs: { outlined: "" }, on: { click: _setup.logout } },
            [_vm._v("ログアウト")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }