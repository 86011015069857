var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.sessonStore.isLogin
    ? _c(
        "v-footer",
        {
          staticClass: "d-flex justify-center",
          attrs: { color: "blue-grey darken-2", dark: "", app: "" },
        },
        [_vm._v(" 土マッチングシステム ")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }