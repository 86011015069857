var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    [
      _c(_setup.Menu),
      _c(_setup.Header),
      _c("v-main", [_c("router-view")], 1),
      _c(_setup.Footer),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }