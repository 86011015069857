import Vue from "vue";
import App from "./App.vue";
import { createPinia, PiniaVuePlugin } from "pinia";
import vuetify from "./plugins/vuetify";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueRouter from "vue-router";
import "./assets/scss/style.scss";
import router from "./router";

Vue.use(PiniaVuePlugin);
Vue.use(VueSweetalert2);
const pinia = createPinia();
Vue.use(VueRouter);
Vue.config.productionTip = false;

new Vue({
    render: (h) => h(App),
    router,
    pinia,
    vuetify,
}).$mount("#app");
