import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import { useSessonStore } from "@/stores/common";

const routes = [
    //ダッシュボードa
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    // 会員一覧
    {
        path: "/member-list",
        name: "memberList",
        component: () => import("../views/MemberListView.vue"),
    },
    {
        path: "/login",
        name: "login",
        component: () => import("../views/LoginView.vue"),
    },
    {
        path: "/member-before-list",
        name: "memberBeforeList",
        component: () => import("../views/MemberBeforeListView.vue"),
    },    
    {
        path: "/soil-list",
        name: "soillist",
        component: () => import("../views/SoilListView.vue"),
    },
    {
        path: "/apitest",
        name: "apitest",
        component: () => import("../views/ApitestView.vue"),
    },
    {
        path: "/about",
        name: "about",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    },
    {
        path: "/apprpval-email",
        name: "approvalEmail",
        component: () => import("../views/ApprovalEmailView.vue"),
    },
    // 会員承認
    {
        path: "/approval",
        name: "approval",
        component: () => import("../views/ApprovalView.vue"),
    },
    // 会員照会
    {
        path: "/member-show",
        name: "memberShow",
        component: () => import("../views/MemberShowView.vue"),
    },
    // 会員編集
    {
        path: "/member-edit",
        name: "memberEdit",
        component: () => import("../views/MemberEditView.vue"),
    },
    //搬入搬出土の詳細
    {
        path: "/soil-show",
        name: "soilShow",
        component: () => import("../views/SoilShowView.vue"),
    },
    //搬入搬出土の編集
    {
        path: "/soil-edit",
        name: "soilEdit",
        component: () => import("../views/SoilEditView.vue")
    },
    {
        path: "/timeout",
        name: "Timeout",
        component: () => import("../views/Timeout.vue"),
    },
    {
        path: "/validatetest",
        name: "validatetest",
        component: () => import("../views/ValidateTest.vue"),
    },
    {
        path: "/information",
        name: "information",
        component: () => import("../views/InformationView.vue"),
    },
    {
        path: "/information-edit",
        name: "informationEdit",
        component: () => import("../views/InformationEditView.vue"),
    },
    {
        path: "/member-new",
        name: "memberNew",
        component: () => import("../views/MemberNewView.vue"),
    },
    {
        path: "/mail-list",
        name: "mailList",
        component: () => import("../views/MailList.vue"),
    },
    {
        path: "/mail-edit",
        name: "mailEdit",
        component: () => import("../views/MailEdit.vue"),
    },
    {
        path: "/admin-list",
        name: "adminList",
        component: () => import("../views/AdminList.vue"),
    },
    {
        path: "/admin-edit",
        name: "adminEdit",
        component: () => import("../views/AdminEdit.vue"),
    },
    {
        path: "/matching-list",
        name: "matchingList",
        component: () => import("../views/MatchingListView.vue"),
    },
    {
        path: "/matching-detail",
        name: "matchingDetail",
        component: () => import("../views/MatchingDetailView.vue"),
    },
    {
        path: "/kiyaku",
        name: "kiyaku",
        component: () => import("../views/KiyakuEditView.vue"),
    },
    { path: "/soil-map",
        name: "soilMap",
        component: () => import("../views/SoilMapView.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    const sessionStore = useSessonStore();
    console.log(sessionStore);

    console.log(to.name);
    console.log(sessionStore.isLogin);
    if (!sessionStore.isLogin && to.name !== "login") {
        next({ name: "login" });
    } else {
        next();
    }
});

export default router;
