export class CONSTANT {
    /********************
     * API戻り値
     ********************/
    /**  正常 */
    static RETURN_NORMAL = "0";
    /** データなし */
    static RETURN_NOT_FOUND = "1";
    /** DBワーニング */
    static RETURN_DB_WARNING = "2";
    /** 致命的エラー */
    static RETURN_INTERNAL_ERROR = "9";

    /********************
     * 稼働ステータス
     ********************/
    /**  稼働中 */
    static KADO_STATUS_KADO = "0";
    /**  停止 */
    static KADO_STATUS_TEISHI = "1";
    /**  時間指定 */
    static KADO_STATUS_JIKAN = "2";

    /********************
     * 会員ステータス
     ********************/
    static MEMBER_STATUS_KADO = 1;
    static MEMBER_STATUS_TEISHI = 2;
    static MEMBER_STATUS_HININ = 3;
    static MEMBER_STATUS_TAIKAI = 9;

    /********************
     * 処理モード
     ********************/
    static MODE_UPDATE = "U";
    static MODE_ADD = "A";
    static MODE_DEL = "D";

    /********************
     * 会員ステータス
     ********************/
    static MEMBER_STATUS = [
        { status: 0, value: "新規" },
        { status: 1, value: "稼働中" },
        { status: 2, value: "停止中" },
        { status: 3, value: "申込否認" },
        { status: 9, value: "退会" },
    ];

    /********************
     * マッチングステータス
     ********************/
    static MATCHING_STATUS = [
        { status: 0, value: "−−−" },
        { status: 1, value: "交渉中" },
        { status: 2, value: "成立" },
        { status: 9, value: "不成立" },
    ];
    /********************
     * 会員種別
     ********************/
    static MEMBER_TYPE = [
        { status: 1, value: "正会員" },
        { status: 2, value: "賛助会員" },
        { status: 3, value: "無料会員" },
    ];
    /********************
     * 業者種別
     ********************/
    static VENDOR_TYPE = [
        { status: 1, value: "リサイクル業者" },
        { status: 2, value: "工事事業者" },
        { status: 3, value: "その他" },
    ];
    /********************
     * 各区分分類コード
     ********************/
    //土質区分
    static KUBUN_DOSHITSU = "01";
    //工事種類名
    static KUBUN_KOJI = "02";
    //搬入用途
    static KUBUN_HANNYU_YOTO = "03";
    //土質試験の実施
    static KUBUN_DOSHITSU_KENSA = "04";
    //仮置場
    static KUBUN_KARIOKIBA = "05";
    //運搬条件
    static KUBUN_UMPAN_JOKEN = "06";
    //時間条件
    static KUBUN_JIKAN_JOKEN = "07";
    //都道府県
    static KUBUN_TDFK = "20";

    /********************
     * 搬入・搬出
     ********************/
    //搬出
    static SOIL_HANSHUTU = 1;
    //搬入
    static SOIL_HANNYU = 2;
    /********************
     * メールID
     ********************/
    static MAIL = {
        //foget password
        REPASSWORD: 1,
        //change member data
        UPDATE_MEMBER: 2,
    };
}
