<template>
    <div>
        <v-container fluid class="mt-0 pa-0">
            <v-sheet
                elevation="1"
                height="40"
                rounded="false"
                class="white--text light-blue pt-2 pl-3"
            >
                <v-icon class="white--text mb-2">mdi-home</v-icon>
                ダッシュボード
            </v-sheet>
            {{/*ここまでヘッダ*/}}
            </v-container>
            <v-container fluid v-if="isMount">

                <v-card color="blue darken-4 white--text" class="rounded-b-0 rounded-t-lg">
                    <v-card-title class="py-2">
                       会員状況 
                    </v-card-title>
                    <v-card-text class="" style="background-color:white">
                            <v-container fluid>
                                <v-row>
                                    <v-col md="3">
                                        <v-simple-table class="sms-data-table">
                                            <colgroup>
                                                <col style="width:180px" />
                                            </colgroup>
                                            <tr>
                                                <th>全会員数</th>
                                                <td class="pa-2 text-right">{{helpers.numberFormat(dashBordInf.member_counts.totalCnt)}}</td>
                                            </tr>
                                            <tr>
                                                <th>新規申込</th>
                                                <td class="pa-2 text-right">{{helpers.numberFormat(dashBordInf.member_counts.newCnt)}}</td>
                                            </tr>
                                            <tr>
                                                <th>稼働中</th>
                                                <td class="pa-2 text-right">{{helpers.numberFormat(dashBordInf.member_counts.kadoCnt)}}</td>
                                            </tr>
                                            <tr>
                                                <th>退会</th>
                                                <td class="pa-2 text-right">{{helpers.numberFormat(dashBordInf.member_counts.taikaiCnt)}}</td>
                                            </tr>
                                            <tr>
                                                <th>停止中</th>
                                                <td class="pa-2 text-right">{{helpers.numberFormat(dashBordInf.member_counts.teishiCnt)}}</td>
                                            </tr>
                                        </v-simple-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                    </v-card-text>
                </v-card>

            </v-container>
    </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
//import { MESSAGE } from "@/messages";
import { CONSTANT } from "@/constant";
//import { useRouter } from "vue-router/composables";
//import { swalConfirm, swalToast } from "@/swaldialog";
import api from "@/plugins/axios";
import { helpers } from "@/commons/helpers";
//import { useInfomationStore } from "@/stores/processStore";

const dashBordInf = ref();
const isMount = ref(false);

onMounted(async () => {
    await getDashBordInfo();
    isMount.value = true;
});
const getDashBordInfo = async () => {
    const res = await api.get("admin/dash-bord-info");
    if (res.data.resultCode === CONSTANT.RETURN_NORMAL) {
        dashBordInf.value = res.data.resultData.dash_bord_info;
    }
    console.log(dashBordInf);
};
</script>
