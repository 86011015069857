<template>
    <v-card v-if="sessionStore.isLogin" style="z-index:1000">
        <v-navigation-drawer app clipped v-model="commonStore.isMenuShow">
            <v-container class="pl-0 pr-0">
                <v-list dense nav>
                    <v-list-item-group v-model="selectedMenu" color="blue">
                        <v-list-item to="/">
                            <v-list-item-icon>
                                <v-icon>mdi-home</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title
                                    >ダッシュボード</v-list-item-title
                                >
                            </v-list-item-content>
                        </v-list-item>

                        <v-toolbar
                            elevation="2"
                            color="blue lighten-3 mb-2"
                            dense
                        >
                            <v-toolbar-title class="text-subtitle-1"
                                >会員管理</v-toolbar-title
                            >
                        </v-toolbar>

                        <v-list-item
                            v-for="nav_list in nav_member_lists"
                            :key="nav_list.no"
                            :to="nav_list.link"
                        >
                            <v-list-item-icon>
                                <v-icon>{{ nav_list.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title
                                    v-text="nav_list.name"
                                ></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-toolbar
                            elevation="2"
                            color="blue lighten-3 mb-2"
                            dense
                        >
                            <v-toolbar-title class="text-subtitle-1"
                                >搬入・搬出管理</v-toolbar-title
                            >
                        </v-toolbar>

                        <v-list-item
                            v-for="nav_list in nav_match_lists"
                            :key="nav_list.no"
                            :to="nav_list.link"
                        >
                            <v-list-item-icon>
                                <v-icon>{{ nav_list.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    nav_list.name
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-toolbar
                            elevation="2"
                            color="blue lighten-3 mb-2"
                            dense
                        >
                            <v-toolbar-title class="text-subtitle-1"
                                >メンテナンス</v-toolbar-title
                            >
                        </v-toolbar>

                        <v-list-item
                            v-for="nav_list in nav_mente_lists"
                            :key="nav_list.no"
                            :to="nav_list.link"
                        >
                            <v-list-item-icon>
                                <v-icon>{{ nav_list.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    nav_list.name
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                      
                    </v-list-item-group>
                </v-list>
            </v-container>
        </v-navigation-drawer>
    </v-card>
</template>

<script setup>
import { ref } from "vue";
import { useCommonStore, useSessonStore } from "@/stores/common";

const selectedMenu = ref(1);
const commonStore = useCommonStore();
const sessionStore = useSessonStore();
const nav_member_lists = [
    {
        name: "会員一覧",
        icon: "mdi-account-multiple",
        link: "/member-before-list",
        no: 10,
    },
    {
        name: "会員登録",
        icon: "mdi-account",
        link: "/member-new",
        no: 11,
    },
    {
        name: "お知らせ",
        icon: "mdi-information",
        link: "/information",
        no: 12,
    },
];
const nav_match_lists = [
    {
        name: "搬入・搬出土一覧",
        icon: "mdi-account-multiple",
        link: "/soil-list",
        no: 20,
    },
    {
        name: "搬入・搬出土マップ",
        icon: "mdi-account-multiple",
        link: "/soil-map",
        no: 21,
    },
    {
        name: "マッチング一覧",
        icon: "mdi-account-multiple",
        link: "/matching-list",
        //link: clickMatching(),
        no: 22,
    },
];
const nav_mente_lists = [
    {
        name: "メールテンプレート",
        icon: "mdi-account-multiple",
        link: "/mail-list",
        no: 30,
    },
    {
        name: "管理者一覧",
        icon: "mdi-account-multiple",
        link: "/admin-list",
        no: 31,
    },
    {
        name: "規約編集",
        icon: "mdi-account-multiple",
        link: "/kiyaku",
        no: 32,
    },
];
// const clickMatching = () => {
//     console.log("mat");
// };
</script>
