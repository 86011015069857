var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "mt-0 pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-sheet",
            {
              staticClass: "white--text light-blue pt-2 pl-3",
              attrs: { elevation: "1", height: "40", rounded: "false" },
            },
            [
              _c("v-icon", { staticClass: "white--text mb-2" }, [
                _vm._v("mdi-home"),
              ]),
              _vm._v(" ダッシュボード "),
            ],
            1
          ),
          _vm._v(" " + _vm._s(/*ここまでヘッダ*/) + " "),
        ],
        1
      ),
      _setup.isMount
        ? _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "rounded-b-0 rounded-t-lg",
                  attrs: { color: "blue darken-4 white--text" },
                },
                [
                  _c("v-card-title", { staticClass: "py-2" }, [
                    _vm._v(" 会員状況 "),
                  ]),
                  _c(
                    "v-card-text",
                    { staticStyle: { "background-color": "white" } },
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { md: "3" } },
                                [
                                  _c(
                                    "v-simple-table",
                                    { staticClass: "sms-data-table" },
                                    [
                                      _c("colgroup", [
                                        _c("col", {
                                          staticStyle: { width: "180px" },
                                        }),
                                      ]),
                                      _c("tr", [
                                        _c("th", [_vm._v("全会員数")]),
                                        _c(
                                          "td",
                                          { staticClass: "pa-2 text-right" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _setup.helpers.numberFormat(
                                                  _setup.dashBordInf
                                                    .member_counts.totalCnt
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("tr", [
                                        _c("th", [_vm._v("新規申込")]),
                                        _c(
                                          "td",
                                          { staticClass: "pa-2 text-right" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _setup.helpers.numberFormat(
                                                  _setup.dashBordInf
                                                    .member_counts.newCnt
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("tr", [
                                        _c("th", [_vm._v("稼働中")]),
                                        _c(
                                          "td",
                                          { staticClass: "pa-2 text-right" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _setup.helpers.numberFormat(
                                                  _setup.dashBordInf
                                                    .member_counts.kadoCnt
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("tr", [
                                        _c("th", [_vm._v("退会")]),
                                        _c(
                                          "td",
                                          { staticClass: "pa-2 text-right" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _setup.helpers.numberFormat(
                                                  _setup.dashBordInf
                                                    .member_counts.taikaiCnt
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("tr", [
                                        _c("th", [_vm._v("停止中")]),
                                        _c(
                                          "td",
                                          { staticClass: "pa-2 text-right" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _setup.helpers.numberFormat(
                                                  _setup.dashBordInf
                                                    .member_counts.teishiCnt
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }