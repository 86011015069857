/**
 * axios の共通化
 */
import axios from "axios";
import { useSessonStore } from "@/stores/common";
//import { useRouter } from "@/commons/useRouter";

const axios_instance = axios.create();
axios_instance.interceptors.request.use((config) => {
    const sessionStore = useSessonStore();
    config.headers = {
        Authorization: "Bearer " + sessionStore.token,
    };
    config.baseURL = process.env.VUE_APP_API_BASE;
    return config;
});

axios_instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            window.location = "/timeout";
        }
        return error;
    }
);
export default axios_instance;
